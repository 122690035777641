<template>
  <!-- <footer class="footer bg-dark pt-4">
      <div class="container py-2">
        <p class="fs-sm text-center text-sm-start mb-4"><span class="text-light opacity-50">&copy; Syndesi One Limited. All rights reserved.</span></p>
      </div>
    </footer> -->
  <!-- Powered by Syndesi -->
  <footer class="footer pt-10 text-center mt-5 pb-4">
    Powered by <a target="_blank" href="https://www.syndesi.network/"><img style="height: 20px; margin-left: 5px;"
        src="/img/logo-light.png" alt="Syndesi"></a>

    <div class="mt-2">
      <small>All rights reserved.</small>
    </div>

    <div class="links mt-2">
      <a target="_blank"
        href="https://cdn.prod.website-files.com/64943ca2ceb618f0fa485f81/67698ccc121a5a8c60e9b788__Syndesi%20Platform%20Terms.docx.pdf">
        <small>Terms & Conditions</small>
      </a>

      <a class="ms-3" target="_blank"
        href="https://cdn.prod.website-files.com/64943ca2ceb618f0fa485f81/67698cccb85d917b09a00bec_Syndesi%20Privacy%20Notice%20(1).pdf">
        <small>Privacy Policy</small>
      </a>
    </div>
  </footer>
  <!-- / Powered by Syndesi -->
</template>

<script>
export default {
  setup() {

  },
}
</script>


<style></style>
